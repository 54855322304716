import React, { useState } from 'react';
import { IoIosArrowDropup, IoIosArrowDropdown } from 'react-icons/io';
import { Container, Main, Title, Content } from './styles';

const FAQAccordion = ({
  question,
  children,
  headerBg = 'bg-primaryLight',
  headerTextColor = '',
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Main>
      <Container className={` ${headerBg} ${headerTextColor} `} onClick={() => setOpen(!open)}>
        <Title>{question}</Title>
        {open ? <IoIosArrowDropup size={24} /> : <IoIosArrowDropdown size={24} />}
      </Container>
      {open ? <Content>{children}</Content> : null}
    </Main>
  );
};

export default FAQAccordion;
