import React from 'react';
import { graphql } from 'gatsby';

import { Hero } from '../../components/Hero/';
import PageLayout from '../../components/PageLayout/';
import SEO from '../../components/seo';
import FAQAccordion from '../../components/FAQAccordion/';
import { Badges } from '../../components/Badges/';
import {
  BusinessSubTitle,
  CTASubTitle,
  CTATitle,
  CTAWrapper,
  Wrapper,
} from '../../styles/faq-styles';

const BusinessFAQ = ({ data: { HeroImage } }) => (
  <PageLayout className="bg-white">
    <SEO title="Häufig gestellte Fragen - Unternehmen" />
    <Hero
      image={HeroImage}
      title="Häufig gestellte Fragen"
      subTitle="zu unseren Unternehmenslösungen">
      <div className="font-display font-xl tracking-wide">
        Jetzt die DropFriends-App downloaden.
      </div>

      <Badges />
    </Hero>
    <Wrapper>
      <FAQAccordion question="Was für Angebote bietet DropFriends an?">
        <p className="mb-4">
          Wir bei DropFriends bieten drei verschiedene Kern-B2B-Services an, damit Du genau das
          passende Angebot für Dein Unternehmen auswählen kannst. Dabei unterscheiden wir zwischen
          DropFriends SPRINT, DropFriends BUSINESS und unserer DropFriends API.
        </p>

        <h2 className="font-display text-2xl font-bold mb-2 tracking-wide">DropFriends SPRINT</h2>
        <p className="mb-4">
          DropFriends SPRINT ist eine Online-Software, die Deinem Unternehmen die Möglichkeit gibt,
          einen eigenen Multi-Kanal-Handel einzurichten – mit wenigen Klicks. Das heißt, dass wir
          Deinen Online- und Offlinehandel verbinden und ermöglichen, zur Reduzierungen Deiner
          Kosten, Verringerung Deines Aufwands sowie der erheblichen Senkung Deines gewerblichen
          CO2-Fußabdrucks. Zudem ergibt sich ein geringerer Serviceaufwand von bis zu 25% durch die
          ausbleibenden Nachfragen und Beschwerden über den Aufenthalt, wie auch Paketzustände der
          Sendungen.
        </p>
        <h3 className="font-display text-lg font-bold mb-1 tracking-wide">
          Same Day Unpacking, statt Next Day Delivery
        </h3>
        <p className="mb-4">
          Durch DropFriends SPRINT wird Deine online verkaufte Ware (Click & Collect) an Deine
          lokalen Kunden übergeben - bei einem für sie relevanten Standort. Wir nennen diese
          DropPoints. Deine Kunden erhalten ihren Einkauf unabhängig von den Öffnungszeiten Deines
          Ladenlokals. Sogar in der Nacht oder am Morgen. So bist Du dem eCommerce immer einen
          Schritt voraus.
        </p>
        <p className="mb-4">
          Zusätzlich sichert Dir DropFriends SPRINT relevante Werbeflächen auf der DropFriends
          Plattform, um Dir Deine digitale und relevante Laufkundschaft zu sichern.
        </p>
        <h3 className="font-display text-lg font-bold mb-1 tracking-wide">
          Umsatzbooster für Großevents
        </h3>
        <p className="mb-4">
          Auch für Messen und große Events ist DropFriends SPRINT perfekt geeignet. Deine Besucher
          buchen Deine Warenübergabe-Standorte auf Deinem Veranstaltungsgelände oder nutzen bereits
          existente DropPoints in der direkten Nachbarschaft. Produkt-, Ticket oder
          Dokumentübergaben werden so strukturiert geplant, sind sicher und werden ohne Wartezeiten
          für Deine Veranstaltungsbesucher gestaltet. Zusätzlich werden Deine Händler vor Ort über
          Event-Apps oder zentrale Event-Online-Shops sichtbarer und profitieren so von steigenden
          Umsatzzahlen. Lange Lieferwege und Menschenansammlungen werden vermieden und die Besucher
          können sich entspannt sowie ohne Einschränkungen auf das Event konzentrieren.
        </p>

        <h2 className="font-display text-2xl font-bold mb-2 tracking-wide">DropFriends API</h2>
        <p className="mb-4">
          Unsere Public API ermöglicht es Dir DropFriends direkt in Deinen Online-Shop oder Deine
          eigenen Systeme zu integrieren. Dadurch können Deine Kunden z.B. während ihres Einkaufs
          lieferdienstunabhängige Ablagestandorte in der Nähe wählen.
        </p>
        <p className="mb-4">
          Für Deine Mitarbeiter ergeben sich transparentere Prozesse und ein geringerer
          Serviceaufwand von bis zu 25 % durch ausbleibende Nachfragen und Beschwerden über den
          Aufenthalt, wie auch Paketzustände von Sendungen.
        </p>
        <p className="mb-4">
          Sie können zusätzlich in ihrer gewohnten Softwareumgebung z.B. DropPoints buchen,
          Paketzustände nach Warenablagen einsehen und Paketübergaben verfolgen und für sich
          bewerten. Gerne unterstützen wir Dich und Dein Team bei der Integration und beraten
          dahingehend gerne. Sprich uns diesbezüglich an.
        </p>

        <h2 className="font-display text-2xl font-bold mb-2 tracking-wide">DropFriends BUSINESS</h2>
        <p className="mb-4">
          Mit DropFriends BUSINESS wird Dein Ladenlokal oder sogar ganze Filialstrukturen zum
          perfekten Paketübergabeort (DropPoint) für Dein Stadtviertel. Die Teams auf den
          Ladenflächen können einfach und sicher eintreffende Paketsendungen und Click & Collect
          Einkäufe per Smartphone dokumentieren und verwalten. Die Sendungseigentümer werden in
          Echtzeit über die Paketaufbewahrung durch Deine Filiale informiert, sodass eine
          tagesgleiche Abholung forciert wird. DropFriends übernimmt dabei die gesamte Kommunikation
          zwischen Dir als DropPoint und den Abholern.
        </p>
        <p className="mb-4">
          Durch die erhöhte Personenfrequenz in Filialen sowie einem individuellen Gutschein
          Programm für Kunden werden Paketabholer zu echten Kunden. So steigert sich Dein Umsatz und
          die Bindung zu Deiner Marke.
        </p>
        <p>
          Mithilfe der Online-Software können einzelne Ladenlokale und selbst Filialen zentral und
          flexibel als DropPoint (de)aktiviert und die entsprechenden lokalen Teams verwaltet
          werden. Deine Mitarbeiter nutzen die intuitive DropFriends Smartphone App zum Sendungs-,
          Identifizierungs- und Übergabemanagement. Natürlich mit intuitiver Rechteverteilung.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Welche Vorteile hat mein Unternehmen durch den DropFriends Service?">
        <p className="mb-4">
          Generell haben unsere verschiedenen Angebote auch unterschiedliche Vorteile, doch viele
          der Vorteile beziehen sich auf den gesamten DropFriends Service.
        </p>

        <BusinessSubTitle>Verringerung Deiner Logistikkosten</BusinessSubTitle>
        <p className="mb-4">
          Dadurch, dass wir durch unseren Service die „Letzte Meile“ der Paketlieferungen
          effizienter gestalten, können Deine Kunden ihre Paketannahmen ihrem Alltag anpassen. So
          werden Rückfragen und mögliche Beschwerden zu den entsprechenden Lieferungen
          ausgeschlossen. Damit sparst Du viel Zeit und Aufwand, sodass sich Deine Servicekosten
          folglich um bis zu 25% reduzieren. Auch mögliche Retouren fallen daraufhin aus.
        </p>
        <BusinessSubTitle>Steigende Kundenzufriedenheit</BusinessSubTitle>
        <p className="mb-4">
          Nicht nur Du sparst Zeit durch die ausfallenden Retouren, sondern auch Deine Kunden. Sie
          können ihre Bestellung noch am selben Tag annehmen und das von einem relevanten
          Ablagestandort in ihrer Nähe. Dadurch fällt Deinen Kunden möglicher Stress weg und sie
          können ihre Paketannahme perfekt an ihren Alltag anpassen.
        </p>
        <BusinessSubTitle>Nachhaltig zur Verringerung von CO2</BusinessSubTitle>
        <p>
          Dir liegt Nachhaltigkeit am Herzen und Du möchtest Dein Angebot um eine grüne
          Logistiklösung ausbauen? Reduziere den CO2-Fußabdruck Deines Unternehmens durch die
          effiziente Paketzustellung unseres Service. Zwei Drittel CO2 pro Paket werden damit
          eingespart. Die Paketzustellung gestaltet sich umweltfreundlicher, effizienter und Dein
          Geschäft profitiert in der Außendarstellung als grüne Marke auf der letzten logistischen
          Meile. Wusstest Du, dass durch vermehrte Zustellungsversuche von Sendungen das
          Durchschnittspaket 1 kg CO2 ausstößt? Da diese Zustellungsversuche und die
          Benachrichtigungskarten durch unseren Service wegfallen, die Wege zur Abholung von Waren
          kürzer und zentrale Anlaufstellen geschaffen werden, verringert sich der CO2-Ausstoß pro
          Paket auf bis zu 277g.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Welches Angebot ist für mein Unternehmen das Richtige?">
        <p className="mb-4">
          Wir bei DropFriends bieten drei verschiedene B2B-Services an, damit Du genau das passende
          Angebot für Dein Unternehmen auswählen kannst. Falls Du Dir nun nicht ganz sicher sein
          solltest, welches Angebot am besten zu Deinem Unternehmen passt, helfen wir Dir gerne
          weiter. Die folgenden Beispiele zeigen, inwiefern unsere verschiedenen Angebote genutzt
          werden können.
        </p>

        <BusinessSubTitle>DropFriends SPRINT</BusinessSubTitle>
        <p className="mb-4">
          Bei DropFriends SPRINT gibt es für verschiedene Unternehmen verschiedene
          Anwendungsmöglichkeiten. DropFriends passt sich individuell an Deine Bedürfnisse an,
          sodass Du davon profitieren kannst. Egal ob als Click & Collect Geschäft oder als
          Online-Shop Eigentümer: Deine Waren werden mit DropFriends SPRINT kinderleicht
          öffnungszeitenunabhängig bei DropPoints in der Nähe Deines Ladenlokals oder Deines Kunden
          platziert. Doch auch Betreiber bzw. Veranstalter von Großevents erhalten Vorteile: Bessere
          Planungen zur Herausgabe von z. B. Messe- oder Merchandise-Waren, Zugangsausweisen oder
          Dokumenten. Für jeden Topf gibt es einen passenden Deckel.
        </p>
        <BusinessSubTitle>DropFriends API</BusinessSubTitle>
        <p className="mb-4">
          Unsere DropFriends API ermöglicht es Dir DropFriends direkt in Deinen Online-Shop oder
          Deine eigenen Systeme zu integrieren. Dadurch können Deine Kunden z.B. während ihres
          Einkaufs lieferdienstunabhängig Ablagestandorte in der Nähe wählen. Solange Du also in
          irgendeiner Form eine Onlineverkaufsfläche für Dein Unternehmen nutzt, ist die DropFriends
          API eine gute Lösung für Dich.
        </p>
        <BusinessSubTitle>DropFriends BUSINESS</BusinessSubTitle>
        <p className="mb-4">
          Mit DropFriends BUSINESS werden Deine Einzelhandelsfilialen zum perfekten Paketübergabeort
          (DropPoint) für Dein Stadtviertel. Ladenlokal- bzw. Filialteams können einfach und sicher
          eintreffende Paketsendungen per Smartphone dokumentieren und verwalten.
        </p>
        <p className="mb-4">
          Die Sendungseigentümer werden in Echtzeit über die Paketaufbewahrung informiert, sodass
          eine tagesgleiche Abholung in Deinen Ladenlokalen forciert wird. DropFriends übernimmt
          dabei die gesamte Kommunikation zwischen Dir als DropPoint und den Abholern.
        </p>
        <p className="mb-4">
          Durch die erhöhte Personenfrequenz in Deinen Geschäftsstandorten sowie einem individuellen
          Gutschein Programm für Kunden werden Paketabholer zu echten Kunden. So steigert sich Dein
          Umsatz und die Bindung zu Deiner Marke.
        </p>
        <p>
          Als Einzelhandel jeglicher Art ist DropFriends BUSINESS also eine schnelle und einfache
          Lösung, um Deinen Umsatz zu steigern.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Gibt es einen Unterschied zwischen DropFriends und Packstationen?">
        <p className="mb-4">
          Ja, es gibt viele Unterschiede zwischen dem DropFriends Service und Packstationen.
        </p>

        <BusinessSubTitle>Lieferdienstunabhängig</BusinessSubTitle>
        <p className="mb-4">
          Der DropFriends Service ist lieferdienstunabhängig. Ein klarer Vorteil gegenüber
          exklusiven Paketschränken von z.B. Amazon oder DHL, die nur von dem entsprechenden
          Anbieter beliefert werden. Oftmals hat man aber als Warenbesteller nicht die Möglichkeit
          selbst zu entscheiden, welcher Dienst für die Zustellung beauftragt wird. Egal für welchen
          Kurier-, Express- oder Postdienst sich Dein Online-Shop entscheidet: DropFriends handelt
          die Annahme für Dich unkompliziert und sicher.
        </p>

        <BusinessSubTitle>Keine Überbuchungen</BusinessSubTitle>
        <p className="mb-4">
          Packstationen können überbucht werden und stehen somit nicht immer zur Verfügung. Bei der
          Zustellung an die entsprechenden Paketschränke kann es bei Platzmangel dann zu
          ungewünschten Weiterleitungen an unbekannte Paketshops oder Postfilialen kommen. Das ist
          zeitintensiv und ärgerlich. Durch unsere ausgefeilten automatischen Prozesse stellen wir
          sicher, dass unsere DropPoints nicht überbucht werden können und die Zustellung somit
          funktioniert. Zu den angegebenen Abholzeiten stehen Dir die Betreiber natürlich zur
          Verfügung.
        </p>

        <BusinessSubTitle>Relevante Standorte</BusinessSubTitle>
        <p className="mb-4">
          Die Vorteile von Packstationen sind leider abhängig von Wartungen, verfügbaren
          Aufstellflächen und Nutzungsfrequenz (siehe Überbuchungen). So sind lange Wege für
          Paketabholende zu den Paketschränken oftmals vorprogrammiert. Das erhöht den Ärger,
          Zeitaufwand und CO2-Ausstoß pro Paket. Das gilt auch für Postfilialen und Paketshops.
          Unsere DropPoints sind an relevanten Standorten in Wohngebieten verfügbar. Das hält die
          Wege kurz und ermöglicht unkomplizierte, wie auch persönliche Paketübergaben.
        </p>

        <BusinessSubTitle>Kein Vandalismus</BusinessSubTitle>
        <p className="mb-4">
          Durch die Positionierung im öffentlichen Raum sind Paketschränke Vandalismus ausgesetzt.
          Dies beeinträchtigt die Funktionsfähigkeit und Sicherheit für Paketsendungen und Benutzer.
          Bei unseren DropPoints werden Pakete persönlich in Empfang genommen, für Dich per Foto und
          Zeitstempel dokumentiert und sicher bis zur Übergabe verwahrt. Die Aushändigung erfolgt
          nach einer eindeutigen Identifizierung an rechtmäßige Eigentümer.
        </p>

        <BusinessSubTitle>Sauber und verfügbar</BusinessSubTitle>
        <p>
          Die Verfügbarkeit von Paketschränken ist abhängig von Wartungs- und Reinigungszyklen durch
          Personal der jeweiligen Anbieter. Dies schränkt die Verfügbarkeit der jeweiligen Services
          ein. Bei Zustellung an die entsprechenden Paketschränken kann es dann zu ungewünschten und
          stressvollen Weiterleitungen an unbekannte Paketshops oder Postfilialen kommen. Unsere
          DropPoints hingegen sind immer zu den angegebenen Abholzeiten durch die Betreiber für Dich
          zugänglich, da Wartungen vor Ort entfallen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Ist DropFriends auch für Events geeignet?">
        <p>
          Ja, unser DropFriends SPRINT Service ermöglicht es Dir, Dein Event effizienter und
          flexibler zu gestalten. Deine Besucher buchen Deine Warenübergabe-Standorte auf Deinem
          Veranstaltungsgelände oder nutzen bereits existente DropPoints in der direkten
          Nachbarschaft. Produkt-, Ticket oder Dokumentübergaben werden so strukturiert geplant,
          sind sicher und werden ohne Wartezeiten für Deine Veranstaltungsbesucher gestaltet. Lange
          Lieferwege und Menschenansammlungen werden vermieden und die Besucher können sich
          entspannt, sowie ohne Einschränkungen auf das Event konzentrieren.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wie schnell kann ich die DropFriends-Services verwenden?">
        <BusinessSubTitle>DropFriends SPRINT und BUSINESS:</BusinessSubTitle>
        <p className="mb-4">
          Unsere beiden Services DropFriends SPRINT und BUSINESS lassen sich unmittelbar nach der
          Registrierung verwenden. Du steuerst Dein Geschäft zentral über unsere Online-Software
          nach einem simplen Login, administrierst Deine Kapazitäten und Abholzeiten der Ladenlokale
          und verwaltest Deine lokalen Mitarbeiter-Teams. Letztere nutzen die intuitive DropFriends
          Smartphone-App, um Paketannahmen auf Deinen Ladenflächen zu verwalten, zu dokumentieren
          und Identifizierungen von Abholern und Übergaben an selbige durchzuführen. Und das alles
          sicher, schnell und unkompliziert.
        </p>

        <BusinessSubTitle>DropFriends API:</BusinessSubTitle>
        <p>
          Deine Kunden können dann direkt auf Deiner Website oder App die für sie relevanten
          Ablagestandorte für gekaufte Waren auswählen. Und falls Du Schwierigkeiten mit der
          Integration unserer API haben solltest, beraten wir Dich gerne und unterstützen mit
          unserem Entwickler-Team. Sprich uns einfach dahingehend an.
        </p>
      </FAQAccordion>
      <FAQAccordion question="DropFriends API & BUSINESS: Mein Unternehmen hat mehrere Geschäftsstellen an verschiedenen Standorten. Können alle Ladenlokale als DropPoint aufgeschaltet werden oder nur ein einzelnes Geschäft?">
        <p>
          Durch unseren DropFriends API bzw. den BUSINESS Service kannst Du selbst bestimmen wie
          viele Deiner Ladenlokale zum lokalen Ablagestandort für das jeweilige Stadtviertel werden
          sollen. Über unsere Online-Software lassen sich diese zentral und flexibel verwalten,
          sodass Du immer entscheiden kannst, welches Geschäft gerade ein DropPoint sein soll und
          welches nicht. Auch die jeweiligen Kapazitäten Deiner Filialen kannst Du differenziert
          voneinander administrieren. Verwalte zudem schnell und einfach Deine lokalen Teams vor Ort
          per Mausklick. Bestimme so welcher Mitarbeiter bei welchem Standort z. B. über die meisten
          Befugnisse verfügen soll. Dadurch hast Du immer die Kontrolle und den vollen Überblick
          über die verschiedenen Standorte Deines Unternehmens.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Ist der DropFriends Service auch für meine Mitarbeiter einfach nutzbar?">
        <p className="mb-4">
          Ja, auch für Deine Mitarbeiter sind unsere verschiedenen Services ganz einfach nutzbar.
          Uns bei DropFriends ist es sehr wichtig, dass unsere B2B-Angebote schnell und leicht in
          den Alltag Deines Unternehmens eingearbeitet werden können. Daher stellen wir stets
          sicher, dass Deine Mitarbeiter in ihrer gewohnten Softwareumgebung weiterarbeiten können.
        </p>

        <BusinessSubTitle>DropFriends SPRINT</BusinessSubTitle>
        <p className="mb-4">
          Bei unserem DropFriends SPRINT Service können Deine Mitarbeiter per Mausklick gewünschte
          DropPoints in der Nähe zur Ablage von verkauften Waren buchen, damit Deine Kunden ihr
          Paket noch am selben Tag abholen und auspacken können. Öffnungszeitenunabhängig.
        </p>

        <BusinessSubTitle>DropFriends API & BUSINESS:</BusinessSubTitle>
        <p className="mb-4">
          Wenn Du Dein Geschäft als DropPoint über die DropFriends API oder DropFriends BUSINESS
          administrierst, können Deine Mitarbeiter rasch und simpel über die Smartphone-App
          Paketsendungen annehmen, selbige verwalten, Abholer identifizieren und somit Pakete an die
          rechtmäßigen Eigentümer übergeben.
        </p>
        <p>
          DropFriends übernimmt bei allen Services immer die Kommunikation zwischen den DropPoints
          und den Abholern.
        </p>
      </FAQAccordion>
    </Wrapper>

    <CTAWrapper>
      <CTATitle>Jetzt die DropFriends-App downloaden.</CTATitle>

      <CTASubTitle>Erhältlich im App Store und im Google Play Store.</CTASubTitle>

      <Badges className="mt-4" />
    </CTAWrapper>
  </PageLayout>
);

export const query = graphql`
  {
    HeroImage: file(relativePath: { eq: "Hero-Business-2.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default BusinessFAQ;
